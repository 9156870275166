<template>
  <filter-language-block
    module-name="campaignPlanning"
    :allow-multiple="false"
    :show-audience="false"
    :use-label="false"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
    @update="handleUpdate"
    @remove="handleRemove"
  />
</template>

<script>
// Import the child component
const FilterLanguageBlock = () => import(/* webpackChunkName: "filter-language-campaign-planning" */ "@/blocks/common/filters/FilterLanguage.vue")

// Export the SFC
export default {
  // Name of the component
  name: "FilterLanguage",

  // Register the components
  components: {
    FilterLanguageBlock
  },

  // Define readonly data variables
  computed: {
    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters['campaignPlanning/findFilterByType']("language"))
    },
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch('campaignPlanning/addFilter', data)
    },

    handleUpdate(data) {
      this.$store.dispatch('campaignPlanning/updateFilter', data)
    },

    handleRemove(data) {
      this.$store.dispatch('campaignPlanning/removeFilter', data.id)
    }
  }
}
</script>
